@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@300;500;700&display=swap);
* {
	box-sizing: border-box;
	font-family: "Exo 2", sans-serif;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 0;
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 8px;
}

*::-webkit-scrollbar-button {
	display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

html {
	background: #181a1e;
}

body {
	margin: 0;
	background: #181a1e;
	color: #ffffff;
	font-size: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0 1000px transparent inset !important;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

